export const colors = {
  neutral: {
    0: "#FFFFFF",
    5: "#FEFEFE",
    10: "#FCFBF9",
    50: "#F7F6F4",
    100: "#F2F1F0",
    200: "#EEEDEC",
    300: "#DBDAD8",
    400: "#C1C0BC",
    500: "#91908E",
    600: "#6C6C6A",
    700: "#4B4B4B",
    800: "#373737",
    900: "#000000",
  },
  primary: {
    gray: {
      50: "#d4d4cf",
      100: "#474746",
      200:"#63695f"
    },
    moss: {
      50: "#ECF0C2",
      100: "#D2D69C",
      400: "#969E34",
      500: "#7D851F",
      600: "#616914",
      700: "#464C0C",
    },
    bay: {
      50: "#E1F3FC",
      100: "#C2E2F2",
      400: "#5694B2",
      500: "#3C718E",
      600: "#245670",
      700: "#114461",
    },
    ivy: {
      50: "#EBFFE5",
      100: "#DCFAD4",
      400: "#90C985",
      500: "#5F9154",
      600: "#46733C",
      700: "#345C2B",
    },
  },
  secondary: {
    gold: {
      50: "#F8F2E5",
      100: "#F1E5CB",
      400: "#E4CA98",
      500: "#D6B064",
      600: "#C7993E",
      700: "#B68B36",
    },
    rust: {
      50: "#F7EDE4",
      100: "#c1000094",
      400: "#BB8959",
      500: "#AD7138",
      600: "#966538",
      700: "#7C4D21",
    },
    poppy: {
      50: "#FFEBEB",
      100: "#F5D3D3",
      400: "#D66D6D",
      500: "#BD4D4D",
      600: "#A33939",
      700: "#822727",
    },
  },
} as const



